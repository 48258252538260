import React, { useState, useEffect } from "react"; // Import useEffect
import axios from "axios";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  Alert,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { PersonAddOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius * 2,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
  },
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  padding: theme.spacing(1.5),
  fontWeight: "bold",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const RegisterForm = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [packageName, setPackageName] = useState(""); // State untuk menyimpan nama paket
  const [phoneNumber, setPhoneNumber] = useState(""); // State untuk nomor telepon
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    const packageId = localStorage.getItem("selectedPackageId");
    const packageName = localStorage.getItem("selectedPackageName"); // Ambil nama paket dari localStorage
    if (packageId) {
      setSelectedPackageId(packageId);
    }
    if (packageName) {
      setPackageName(packageName); // Set nama paket jika tersedia
    }
  }, []);

  // Handle Registrasi
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validasi input
      if (!username || !email || !password || !phoneNumber) {
      setErrorMessage(
        "Username, email, password, dan nomor telepon wajib diisi."
      );
      return;
    }

    if (!selectedPackageId) {
      setErrorMessage("Silakan pilih paket sebelum mendaftar.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Masukkan alamat email yang valid.");
      return;
    }

    if (password.length < 6) {
      setErrorMessage("Password harus terdiri dari minimal 6 karakter.");
      return;
    }

    // Validasi nomor telepon
    const phoneRegex = /^[0-9]{10,15}$/; // Contoh: memvalidasi nomor telepon terdiri dari 10-15 digit
    if (!phoneRegex.test(phoneNumber)) {
      setErrorMessage(
        "Nomor telepon tidak valid. Harus terdiri dari 10 hingga 15 digit."
      );
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/user/register`, {
        username,
        email,
        password,
        phoneNumber, // Sertakan nomor telepon dalam request
        packageId: selectedPackageId, // Kirim ID paket ke server
      });

      setSuccessMessage("Pendaftaran berhasil! Silakan login.");
      setUsername("");
      setEmail("");
      setPassword("");
      setPhoneNumber(""); // Reset nomor telepon setelah berhasil mendaftar
      setErrorMessage("");

      // Navigasi ke halaman login atau beranda setelah pendaftaran berhasil
     navigate("/konfirmasi");
      localStorage.removeItem("selectedPackageId"); // Bersihkan ID paket
      localStorage.removeItem("selectedPackageName"); // Bersihkan nama paket
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Pendaftaran gagal. Silakan coba lagi.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 2,
          }}
        >
          <PersonAddOutlined
            sx={{
              fontSize: 40,
              color: theme.palette.primary.main,
              mb: 1,
            }}
          />
          <Typography component="h1" variant="h5">
            Register
          </Typography>
        </Box>

        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMessage}
          </Alert>
        )}

        {packageName && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Anda memilih paket: {packageName}
          </Alert>
        )}
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

         <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Nomor Telepon"
            type="text" // Ganti dengan "text" karena "phone" bukan tipe input yang valid
            id="phone"
            autoComplete="new-phone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)} // Tambahkan input untuk nomor telepon
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Register"
            )}
          </StyledButton>
          <Box mt={2} textAlign="center">
            <Typography variant="body2">
              Sudah punya akun?{" "}
              <Link href="/login" variant="body2">
                Login di sini
              </Link>
            </Typography>
          </Box>
        </StyledForm>
      </StyledPaper>
    </Container>
  );
};

export default RegisterForm;
