import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import axios from "axios";

const AuthContext = createContext();

// const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "https://gasyoutubelive.my.id";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: API_BASE_URL,
        withCredentials: true,
      }),
    []
  );

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setUser(null);
  }, []);

  const refreshUserData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      return;
    }
    try {
      const response = await axiosInstance.get("/api/user/user", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data); // Pastikan response.data sesuai dengan UserSchema
    } catch (error) {
      // console.error("Gagal memperbarui data pengguna", error);
      logout();
    } finally {
      setLoading(false);
    }
  }, [axiosInstance, logout]);

  useEffect(() => {
    refreshUserData();
  }, [refreshUserData]);

  const login = async (userData) => {
    try {
      const response = await axiosInstance.post("/api/user/login", userData);
      if (response.data.user && response.data.token) {
        setUser(response.data.user); // Pastikan response.data.user sesuai dengan UserSchema
        localStorage.setItem("token", response.data.token);
        return true;
      } else {
        throw new Error("Respons tidak valid dari server");
      }
    } catch (error) {
      // console.error("Kesalahan login:", error);
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message); // Menyampaikan pesan kesalahan yang berasal dari server
      } else {
        throw new Error("Login gagal. Silakan periksa kredensial Anda.");
      }
    }
  };

  const register = async (userData) => {
    try {
      const response = await axiosInstance.post("/api/user/register", userData);
      if (response.data.user && response.data.token) {
        setUser(response.data.user); // Pastikan response.data.user sesuai dengan UserSchema
        localStorage.setItem("token", response.data.token);
        return true;
      } else {
        throw new Error("Respons tidak valid dari server");
      }
    } catch (error) {
      // console.error("Kesalahan pendaftaran:", error);
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message); // Menyampaikan pesan kesalahan yang berasal dari server
      } else {
        throw new Error("Pendaftaran gagal. Silakan coba lagi.");
      }
    }
  };

  const contextValue = useMemo(
    () => ({
      user,
      login,
      logout,
      register,
      refreshUserData,
      isAuthenticated: !!user,
    }),
    [user, logout, refreshUserData]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
