import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";

const Konfirmasi = () => {
  // Detail informasi rekening dan nomor WhatsApp untuk konfirmasi
  const accountDetails = [
    {
      bankName: "Bank BCA",
      accountNumber: "406-205-5182",
      accountHolder: "Wandi Fransto",
      whatsappNumber: "6285716459763", // Format: 628xxxx untuk nomor WhatsApp
    },
    {
      bankName: "Bank BNI",
      accountNumber: "055-147-4778",
      accountHolder: "Wandi Fransto",
    },
  ];

  // Fungsi untuk membuka kontak WhatsApp
  const handleWhatsAppClick = () => {
    const whatsappURL = `https://wa.me/${accountDetails[0].whatsappNumber}?text=Halo,%20saya%20ingin%20mengonfirmasi%20pembayaran%20saya.`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
      padding={3}
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, maxWidth: 500, textAlign: "center" }}
      >
        <Typography variant="h5" gutterBottom>
          Konfirmasi Pembayaran
        </Typography>

        <Typography variant="body1" gutterBottom>
          Silakan transfer pembayaran Anda ke rekening berikut:
        </Typography>

        {accountDetails.map((account, index) => (
          <Box
            key={index}
            sx={{ marginY: 2, padding: 2, bgcolor: "#fafafa", borderRadius: 2 }}
          >
            <Typography variant="subtitle1">
              <strong>Bank:</strong> {account.bankName}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Nomor Rekening:</strong> {account.accountNumber}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Nama Penerima:</strong> {account.accountHolder}
            </Typography>
          </Box>
        ))}

        <Button
          variant="contained"
          color="success"
          startIcon={<WhatsApp />}
          onClick={handleWhatsAppClick}
          sx={{ marginTop: 2 }}
        >
          Konfirmasi via WhatsApp
        </Button>

        <Typography variant="body2" color="textSecondary" gutterBottom>
          Setelah melakukan transfer, silakan konfirmasi melalui WhatsApp.
        </Typography>
      </Paper>
    </Box>
  );
};

export default Konfirmasi;
