import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { Menu, Close } from "@mui/icons-material";

const Logo = styled("img")(({ theme }) => ({
  height: "40px",
  marginRight: theme.spacing(2),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette?.background?.paper || "#fff",
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette?.divider || "#e0e0e0"}`,
}));

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
});

const NavButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  "&.active": {
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette?.primary?.main || "#1976d2"}`,
  },
}));

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const handleLogout = useCallback(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);

  const toggleMenu = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const navItems = React.useMemo(
    () => [
      { label: "Beranda", path: "/" },
      ...(user
        ? [
            { label: "Upload", path: "/upload" },
            { label: "Live", path: "/live" },
          ]
        : [
            { label: "Login", path: "/login" },
            { label: "Register", path: "/register" },
          ]),
    ],
    [user]
  );

  const NavList = React.memo(() => (
    <List>
      {navItems.map((item) => (
        <ListItem
          button={true} // Menambahkan true secara eksplisit
          key={item.label}
          component={Link}
          to={item.path}
          onClick={toggleMenu}
          selected={location.pathname === item.path}
        >
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
      {user && (
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" />
        </ListItem>
      )}
    </List>
  ));

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <Box display="flex" alignItems="center">
          <Link to="/">
            <Typography variant="h6" color="primary">
            Gas YtLive
          </Typography>
          </Link>
         
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={toggleMenu}
            >
              <Menu />
            </IconButton>
            <Drawer anchor="right" open={isOpen} onClose={toggleMenu}>
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleMenu}
                onKeyDown={toggleMenu}
              >
                <Box display="flex" justifyContent="flex-end" p={1}>
                  <IconButton onClick={toggleMenu}>
                    <Close />
                  </IconButton>
                </Box>
                <NavList />
              </Box>
            </Drawer>
          </>
        ) : (
          <Box>
            {navItems.map((item) => (
              <NavButton
                key={item.label}
                component={Link}
                to={item.path}
                color="primary"
                className={location.pathname === item.path ? "active" : ""}
              >
                {item.label}
              </NavButton>
            ))}
            {user && (
              <NavButton color="primary" onClick={handleLogout}>
                Logout
              </NavButton>
            )}
          </Box>
        )}
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
