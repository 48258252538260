import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import LoginForm from "./components/Auth/LoginForm";
import RegisterForm from "./components/Auth/RegisterForm";
import UploadVideo from "./components/Upload/UploadVideo";
import LiveStream from "./components/Live/LiveStream";
import Privacy from "./components/Auth/privacy-policy";
import Konfirmasi from "./components/Konfirmasi/Konfirmasi";
import HomePage from "./HomePage";
import { AuthProvider } from "./context/AuthContext";
import AuthContext from "./context/AuthContext";
import SyaratKetentuan from "./components/Auth/SyatarKetentuan";

function App() {
  return (
    <AuthProvider>
      <Router>
        <MainRoutes />
      </Router>
    </AuthProvider>
  );
}

function MainRoutes() {
  const { user } = useContext(AuthContext);
  const selectedPackage = localStorage.getItem("selectedPackageId");

  return (
    <>
      {user && <Navbar />}
      <Routes>
        <Route path="/" element={user ? <Home /> : <HomePage />} />
        
                <Route
          path="/konfirmasi"
          element={user ? <Navigate to="/" /> : <Konfirmasi />}
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/" /> : <LoginForm />}
        />
        
                <Route
          path="/syarat-ketentuan"
          element={user ? <Navigate to="/" /> : <SyaratKetentuan />}
        />
        
                <Route
          path="/privacy-policy"
          element={user ? <Navigate to="/" /> : <Privacy />}
        />
        
   
        <Route
          path="/register"
          element={
            user ? (
              <Navigate to="/" />
            ) : selectedPackage ? (
              <RegisterForm />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/upload"
          element={user ? <UploadVideo /> : <Navigate to="/login" />}
        />
        <Route
          path="/live"
          element={user ? <LiveStream /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
