import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
  Snackbar,
} from "@mui/material";

import { LiveTv, Schedule, Key } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import moment from "moment-timezone";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "";

const LiveStream = () => {
  const [title, setTitle] = useState("");
  const [streamKey, setStreamKey] = useState("");
  const [streamUrl, setStreamUrl] = useState(""); // State untuk URL custom
  const [videos, setVideos] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [schedule, setSchedule] = useState(null);
  const [stopSchedule, setStopSchedule] = useState(null);
  const [platform, setPlatform] = useState("youtube"); // Default ke YouTube
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const loadVideos = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token autentikasi tidak ditemukan");
      }
      const { data } = await axios.get(`${API_BASE_URL}/api/video/videos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVideos(data);
    } catch (error) {
      setError("Gagal memuat video. Silakan coba lagi.");
      setSnackbarMessage("Gagal memuat video. Silakan coba lagi.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate input fields
    if (!selectedVideoId || !streamKey || !title.trim()) {
      setError("Harap isi semua kolom yang diperlukan.");
      setSnackbarMessage("Harap isi semua kolom yang diperlukan.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    // Construct the data object
    const data = {
      videoId: selectedVideoId,
      streamKey: streamKey,
      title: title.trim(),
      platform: platform, // Ensure platform is passed
    };

    // Only add streamUrl if the platform is custom and URL is provided
    if (platform === "custom" && streamUrl.trim()) {
      data.streamUrl = streamUrl.trim(); // Include custom stream URL
      data.customUrl = streamUrl.trim(); // Send custom URL as customUrl
    } else if (platform !== "custom") {
      // If platform is not custom (e.g., YouTube), do not include streamUrl
      data.streamUrl = ""; // Clear any custom URL if platform is YouTube
    }

    if (schedule) {
      data.scheduleStart = moment(schedule).tz("Asia/Jakarta").format();
    }
    if (stopSchedule) {
      data.scheduleStop = moment(stopSchedule).tz("Asia/Jakarta").format();
    }

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token autentikasi tidak ditemukan");
      }

      await axios.post(
        `${API_BASE_URL}/api/livestreams/livestreams/start`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSnackbarMessage("Siaran langsung berhasil dijadwalkan!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);

      navigate("/");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Gagal menjadwalkan siaran langsung.";
      setError(errorMessage);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: isMobile ? theme.spacing(2) : theme.spacing(4),
      }}
    >
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(3),
          }}
        >
          <LiveTv sx={{ mr: 1 }} />
          Mulai streaming
        </Typography>

        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3),
          }}
        >
          <TextField
            fullWidth
            id="title"
            label="Judul Siaran"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Masukkan judul siaran langsung"
            required
            disabled={loading}
            variant="outlined"
          />

          <FormControl fullWidth variant="outlined">
            <InputLabel id="video-select-label">Pilih Video</InputLabel>
            <Select
              labelId="video-select-label"
              id="video-select"
              value={selectedVideoId}
              onChange={(e) => setSelectedVideoId(e.target.value)}
              required
              disabled={loading}
              label="Pilih Video"
            >
              <MenuItem value="">
                <em>Pilih Video</em>
              </MenuItem>
              {videos.map((video) => (
                <MenuItem key={video._id} value={video._id}>
                  {video.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel id="platform-select-label">Platform</InputLabel>
            <Select
              labelId="platform-select-label"
              id="platform-select"
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              required
              label="Platform"
            >
              <MenuItem value="youtube">YouTube</MenuItem>
              <MenuItem value="custom">Custom Live</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            id="stream-key"
            label="Stream Key"
            value={streamKey}
            onChange={(e) => setStreamKey(e.target.value)}
            placeholder="Enter your stream key"
            required
            variant="outlined"
            InputProps={{
              startAdornment: <Key sx={{ color: "action.active", mr: 1 }} />,
            }}
          />

          {platform === "custom" && (
            <TextField
              fullWidth
              id="stream-url"
              label="Stream URL"
              value={streamUrl}
              onChange={(e) => setStreamUrl(e.target.value)}
              placeholder="Enter your custom streaming URL"
              required
              variant="outlined"
            />
          )}

          <TextField
            type="datetime-local"
            label="Mulai Stream (Opsional)"
            value={schedule}
            onChange={(e) => setSchedule(e.target.value)}
            fullWidth
            margin="normal"
            disabled={loading}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            type="datetime-local"
            label="Berhenti Stream (Opsional)"
            value={stopSchedule}
            onChange={(e) => setStopSchedule(e.target.value)}
            fullWidth
            margin="normal"
            disabled={loading}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? "Menjadwalkan..." : "Mulai Siaran Langsung"}
          </Button>
        </form>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Paper>
  );
};

export default LiveStream;
