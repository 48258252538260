import React from "react";
import "./SyaratKetentuan.css"; // Pastikan untuk menambahkan file CSS ini
const SyaratKetentuan = () => {
  return (
    <div className="terms-and-conditions">
      <h3>
        <strong>Syarat dan Ketentuan Layanan</strong>
      </h3>

      <section>
        <h4>
          <strong>Larangan dan Sanksi</strong>
        </h4>
        <ol>
          <li>
            Demi kenyamanan bersama, dalam ketentuan layanan web live streaming
            ini Pelanggan tidak diperkenankan untuk melakukan tindakan-tindakan
            yang disengaja sehingga mengganggu atau merugikan pihak lainnya,
            yaitu:
            <ol>
              <li>
                Tindakan atau usaha yang menimbulkan gangguan pada server web
                atau menimbulkan ketidaknyamanan pihak lain.
              </li>
              <li>
                Tindakan atau usaha lain yang dapat dianggap telah melanggar
                norma, hukum dan peraturan yang berlaku di wilayah yurisdiksi
                Republik Indonesia.
              </li>
            </ol>
          </li>
          <li>
            <strong>GasYoutubeLive</strong> dalam ketentuan layanan web live
            streaming berhak untuk membekukan atau memutuskan Layanan sebelum
            Pelanggan menyatakan untuk berhenti berlangganan dalam keadaan:
            <ol>
              <li>
                Pelanggan lalai melunasi kewajibannya sesuai dengan prosedur
                yang telah ditentukan Gasyoutubelive, atau;
              </li>
              <li>
                Pelanggan telah melakukan pelanggaran terhadap larangan-larangan
                sebagaimana telah diatur dalam ketentuan ini, atau;
              </li>
              <li>
                Adanya permintaan dari pejabat penyidik negara guna kepentingan
                penyidikan sesuai peraturan dan hukum yang berlaku di Republik
                Indonesia.
              </li>
            </ol>
          </li>
          <li>
            Pembekuan atau pemutusan Layanan dapat didahului dengan
            pemberitahuan/peringatan kepada PELANGGAN ataupun tanpa
            pemberitahuan terlebih dahulu.
          </li>
        </ol>
      </section>

      <section>
        <h4>
          <strong>Jaminan dan Pembatasan Tanggung Jawab</strong>
        </h4>
        <ol>
          <li>
            Pelanggan bertanggung jawab penuh atas isi yang ada pada konten
            video live Pelanggan.
          </li>
          <li>
            <strong>Gasyoutubelive</strong> tidak bertanggung jawab atas isi
            baik sebagian maupun seluruh dari Konten / video Pelanggan.
          </li>
          <li>
            <strong>Gasyoutubelive</strong> berhak untuk melakukan scheduled
            maintenance atau tindakan/kegiatan lain yang dianggap perlu untuk
            meningkatkan kualitas layanan maupun dalam rangka pengamanan
            internal.
          </li>
          <li>
            <strong>Gasyoutubelive</strong> berhak memutuskan koneksi untuk
            sementara waktu dalam keadaan darurat dan mendesak yang dapat
            merugikan Gasyoutubelive ataupun Pelanggan.
          </li>
        </ol>
      </section>

      <section>
        <h4>
          <strong>Biaya dan Pembayaran</strong>
        </h4>
        <ol>
          <li>
            Atas penyediaan Layanan sesuai dengan jenisnya masing-masing, maka
            Gasyoutubelive dalam ketentuan layanan web streaming ini berhak atas
            biaya-biaya berikut ini:
            <ol>
              <li>
                Biaya bulanan wajib dilunasi Pelanggan sebelum layanan dimulai.
              </li>
              <li>
                Biaya penggunaan layanan (billing charge) dihitung sejak paket
                live Pelanggan dipesan.
              </li>
            </ol>
          </li>
          <li>
            Tarif layanan dapat berubah sewaktu-waktu dengan pemberitahuan
            paling lambat 14 (empat belas) hari kalender sebelum diberlakukan
            atau tanpa pemberitahuan.
          </li>
          <li>
            Pelanggan berhak berhenti berlangganan dengan memberikan
            pemberitahuan tertulis 14 (empat belas) hari kalender sebelum
            tanggal akhir bulan pemutusan yang diinginkan.
          </li>
        </ol>
      </section>

      <section>
        <h4>
          <strong>Hukum dan Lain-Lain</strong>
        </h4>
        <ol>
          <li>
            Tidak dilaksanakannya sebagian atau seluruh Ketentuan ini oleh salah
            satu atau kedua belah pihak tidak termasuk sebagai pelanggaran atas
            Ketentuan jika hal-hal tersebut disebabkan oleh keadaan Force
            Majeure (keadaan memaksa).
          </li>
          <li>
            Yang termasuk Force Majeure adalah kejadian-kejadian yang tidak
            dapat diduga, berdampak luas dan yang terjadi diluar kekuasaan
            manusia dan diluar kemampuan Para Pihak untuk mengatasinya, yaitu
            peristiwa-peristiwa atau bencana alam, wabah penyakit (epidemi),
            pemberontakan, huru-hara, perang, kebakaran, sabotase, pemogokan
            umum, petir, putus aliran listrik, atau perubahan keadaan karena
            adanya peraturan pemerintah.
          </li>
          <li>
            Isi dan pelaksanaan dalam ketentuan layanan web streaming ini tunduk
            kepada peraturan dan hukum yang berlaku di wilayah jurisdiksi
            Republik Indonesia.
          </li>
          <li>
            <strong>Gasyoutubelive</strong> bertanggung jawab untuk merahasiakan
            segala informasi data-data Pelanggan sebagaimana didaftarkan,
            kecuali untuk kepentingan penyelidikan Kepolisian Negara Republik
            Indonesia atau keadaan lain yang telah diatur menurut hukum yang
            berlaku di wilayah yurisdiksi Indonesia.
          </li>
          <li>
            <strong>Gasyoutubelive</strong> berhak merubah isi dan Ketentuan ini
            sewaktu-waktu dan Pelanggan berhak menyatakan keberatan atas
            perubahan tersebut serta berhenti menggunakan layanan.
          </li>
          <li>
            Pemutusan layanan sebagai akibat permintaan atau kelalaian Pelanggan
            dalam memenuhi Ketentuan ini tidak menghapuskan kewajiban yang
            terhutang atas penggunaan layanan.
          </li>
        </ol>
      </section>

      <section>
        <h4>
          <strong>Perubahan</strong>
        </h4>
        <p>
          <strong>Gasyoutubelive</strong> berhak untuk merubah baik sebagian
          maupun secara keseluruhan isi dari ketentuan berlangganan ini.
          Demikian juga pelanggan berhak untuk menyatakan tidak setuju atas
          perubahan tersebut dengan mengundurkan diri sebagai pelanggan.
        </p>
      </section>
    </div>
  );
};

export default SyaratKetentuan;
