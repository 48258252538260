// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS for TermsAndConditions Component */

.terms-and-conditions {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  color: #333;
}

.terms-and-conditions h3 {
  text-align: center;
  font-size: 24px;
  color: #444;
  margin-bottom: 20px;
}

.terms-and-conditions h4 {
  font-size: 20px;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-and-conditions section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.terms-and-conditions ol {
  margin: 10px 0;
  padding-left: 20px;
}

.terms-and-conditions ol li {
  margin-bottom: 10px;
}

.terms-and-conditions ol li ol {
  padding-left: 20px;
}

.terms-and-conditions strong {
  color: #222;
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/SyaratKetentuan.css"],"names":[],"mappings":"AAAA,yCAAyC;;AAEzC;EACE,8BAA8B;EAC9B,aAAa;EACb,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* CSS for TermsAndConditions Component */\n\n.terms-and-conditions {\n  font-family: Arial, sans-serif;\n  padding: 20px;\n  max-width: 800px;\n  margin: 0 auto;\n  line-height: 1.6;\n  color: #333;\n}\n\n.terms-and-conditions h3 {\n  text-align: center;\n  font-size: 24px;\n  color: #444;\n  margin-bottom: 20px;\n}\n\n.terms-and-conditions h4 {\n  font-size: 20px;\n  color: #444;\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.terms-and-conditions section {\n  margin-bottom: 20px;\n  padding: 15px;\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n}\n\n.terms-and-conditions ol {\n  margin: 10px 0;\n  padding-left: 20px;\n}\n\n.terms-and-conditions ol li {\n  margin-bottom: 10px;\n}\n\n.terms-and-conditions ol li ol {\n  padding-left: 20px;\n}\n\n.terms-and-conditions strong {\n  color: #222;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
