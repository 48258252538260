import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "@mui/material";
import "./home.css"; // Pastikan untuk menambahkan file CSS ini

// const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "https://gasyoutubelive.my.id";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "";
  
  

const HomePage = () => {
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackages = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/packages`);
        setPackages(response.data);
      } catch (error) {
        // console.error("Gagal mengambil data paket:", error);
        setError("Gagal mengambil data paket. Silakan coba lagi.");
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  const handleSelectPackage = (packageId) => {
    localStorage.setItem("selectedPackageId", packageId);
    navigate("/register");
  };

  const handleLogin = () => {
    window.location.href = "/login";
  };

  return (
    <div className="landing-page">
      <header className="header">
        <div className="logo">
          <p>Gas YTLive</p>
        </div>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
      </header>
      <main>
        <h2 className="tagline">PILIH PAKET & NYALAKAN KREASIMU</h2>
         <p className="pgasyt">
          Gasyoutubelive adalah layanan multi live streaming youtube 24/7 dengan
          biaya yang terjangkau. Hadir atas kepedulian Kami kepada kamu yang
          membutuhkan alat untuk melakukan multi live streaming medsos terutama
          youtube 24/7 dengan biaya yang terjangkau. Silahkan tentukan paket live
          yang kamu inginkan di bawah ini:
        </p>
        {loading && <p className="loading-message">Loading...</p>}
        {error && <p className="error-message">{error}</p>}
        <div className="package-list">
          {packages.map((pkg) => (
            <div className="package-card" key={pkg._id}>
              <h3 className="package-name">{pkg.name}</h3>
              <p className="package-details">
                Max Live Streams: {pkg.maxStreams}
              </p>
              <p className="package-details">
                Storage: {(pkg.storage / (1024 * 1024)).toFixed(2)} MB
              </p>

              <p className="package-details">
                Durasi: {pkg.packageDuration} hari
              </p>

              <p className="package-price">Harga: Rp. {pkg.price} </p>
              <button
                className="select-button"
                onClick={() => handleSelectPackage(pkg._id)}
              >
                Pilih {pkg.name}
              </button>
            </div>
          ))}
        </div>
      </main>
     <footer className="footer">
        2024 Gas YTLive. All rights reserved
        <br />
        <Link href="/syarat-ketentuan" target="_blank" rel="noopener">
          Syarat & Ketentuan
        </Link>{" "}
        dan{" "}
        <Link href="/privacy-policy" target="_blank" rel="noopener">
          Kebijakan Privasi
        </Link>
      </footer>
    </div>
  );
};

export default HomePage;
