import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery,
  ListItemSecondaryAction,
  Snackbar,
  Alert,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloudUpload, PlayArrow, Delete, Close } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";

const Input = styled("input")({
  display: "none",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "https://gasyoutubelive.my.id";

const API_BASE_URL =process.env.REACT_APP_API_BASE_URL || "";



const UploadVideo = () => {
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const showSnackbar = useCallback((message, severity) => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const loadVideos = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        showSnackbar("Anda harus login untuk melihat video.", "error");
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/api/video/videos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVideos(response.data);
    } catch (error) {
      // console.error("Gagal memuat video:", error);
      showSnackbar("Gagal memuat video.", "error");
    }
  }, [showSnackbar]);

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  const handleVideoChange = (e) => {
  const selectedFile = e.target.files[0];
  if (selectedFile && selectedFile.type.startsWith("video/")) {
    if (selectedFile.size > 2 * 1024 * 1024 * 1024) { // 2 GB
      showSnackbar("Ukuran video harus kurang dari 2GB", "error");
      setVideo(null);
    } else {
      setVideo(selectedFile);
    }
  } else {
    showSnackbar("Silakan pilih file video yang valid.", "error");
    setVideo(null);
  }
};

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!video || !title.trim()) {
      showSnackbar("Silakan sediakan file video dan judul.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("video", video);
    formData.append("title", title.trim());

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        showSnackbar("Anda harus login untuk mengunggah video.", "error");
        return;
      }

      // Reset progres setiap kali unggahan dimulai
      setUploadProgress(0);

      // Gunakan Axios untuk mengunggah dengan progres
      await axios.post(`${API_BASE_URL}/api/video/upload/videos`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        // Menambahkan onUploadProgress untuk menangani progres upload
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress); // Set progres berdasarkan persentase
        },
      });

      showSnackbar("Video berhasil diunggah", "success");
      setTitle("");
      setVideo(null);
      loadVideos();
      setUploadProgress(0); // Reset progres setelah unggah selesai
    } catch (error) {
      //console.error("Gagal mengunggah:", error);
      showSnackbar("Gagal mengunggah. Silakan coba lagi.", "error");
      setUploadProgress(0); // Reset progres jika gagal
    }
  };

  const handleDelete = async (videoId) => {
    if (!window.confirm("Apakah Anda yakin ingin menghapus video ini?")) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        showSnackbar("Anda harus login untuk menghapus video.", "error");
        return;
      }

      // Lakukan permintaan DELETE ke server backend
      await axios.delete(`${API_BASE_URL}/api/video/videos/${videoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      showSnackbar("Video berhasil dihapus", "success");
      loadVideos(); // Muat ulang video setelah dihapus
    } catch (error) {
      // console.error("Gagal menghapus:", error);
      showSnackbar("Gagal menghapus. Silakan coba lagi.", "error");
    }
  };

  const handlePreview = (videoUrl) => {
    // console.log("Previewing video URL:", videoUrl); // Menambahkan log untuk memeriksa URL
    setPreviewUrl(videoUrl);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledPaper elevation={3}>
            <Typography variant="p" gutterBottom color="primary">
              Unggah Video
            </Typography>
            <form onSubmit={handleUpload}>
              <TextField
                fullWidth
                label="Judul Video"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                margin="normal"
                variant="outlined"
                required
              />
              <Box mt={2}>
                <label htmlFor="video-upload">
                  <Input
                    id="video-upload"
                    type="file"
                    onChange={handleVideoChange}
                    accept="video/*"
                  />
                  <StyledButton
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUpload />}
                    fullWidth
                  >
                    Pilih File Video
                  </StyledButton>
                </label>
              </Box>
              {video && (
                <Typography variant="body2" mt={1}>
                  File terpilih: {video.name}
                </Typography>
              )}

              {/* Progress Bar */}
              {uploadProgress > 0 && (
                <Box mt={2}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                  <Typography variant="body2" mt={1}>
                    {uploadProgress}% terunggah
                  </Typography>
                </Box>
              )}

              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!video || !title}
                startIcon={<CloudUpload />}
              >
                Unggah
              </StyledButton>
            </form>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper elevation={3}>
            <Typography variant="p" gutterBottom color="primary">
              List Video
            </Typography>
            <List>
              {videos.length > 0 ? (
                videos.map((video) => (
                  <ListItem key={video._id} divider>
                    <ListItemText primary={video.title} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="pratinjau"
                        onClick={() => handlePreview(video.videoUrl)}
                      >
                        <PlayArrow />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="hapus"
                        onClick={() => handleDelete(video._id)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="Tidak ada video tersedia" />
                </ListItem>
              )}
            </List>
          </StyledPaper>
        </Grid>
      </Grid>

      <Dialog
        open={Boolean(previewUrl)}
        onClose={() => setPreviewUrl("")}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Pratinjau Video
          <IconButton
            aria-label="tutup"
            onClick={() => setPreviewUrl("")}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" width="100%">
            <video width={isMobile ? "100%" : "80%"} controls>
              <source src={previewUrl} type="video/mp4" />
              Browser Anda tidak mendukung tag video.
            </video>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UploadVideo;
