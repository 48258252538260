import { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Grid2,
    Paper,
  useMediaQuery,
  useTheme,
  DialogContentText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment-timezone";
import "moment/locale/id";

// const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "https://gasyoutubelive.my.id";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "";

const HomeScreen = () => {
  const [liveStreams, setLiveStreams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState(""); // "stop", "restart", "delete"
  const [selectedStreamId, setSelectedStreamId] = useState(null);
  const [notification, setNotification] = useState("");
  const [message, setMessage] = useState(""); // Untuk menyimpan pesan dari backend
  const [userPackage, setUserPackage] = useState(null); // State untuk informasi paket user
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedStream, setSelectedStream] = useState(null);
  const [showRenewButton, setShowRenewButton] = useState(false);
  const [remainingDays, setRemainingDays] = useState(null);
  const [openDialogPaket, setOpenDialogPaket] = useState(false);
  const [openSnackbarPaket, setOpenSnackbarPaket] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [newStartTime, setNewStartTime] = useState(
    moment().tz("Asia/Jakarta").format("YYYY-MM-DDTHH:mm")
  );
  const [newStopTime, setNewStopTime] = useState(
    moment().tz("Asia/Jakarta").format("YYYY-MM-DDTHH:mm")
  );

  useEffect(() => {
    if (remainingDays !== null) {
      if (remainingDays <= 7) {
        setShowRenewButton(true);
      } else {
        setShowRenewButton(false);
      }
    }
  }, [remainingDays]);

  useEffect(() => {
    // Fungsi untuk mengambil data live streams
    const fetchLiveStreams = async () => {
      setLoading(true);
      setError(null);

      try {
        // Ambil token autentikasi dari localStorage
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token autentikasi tidak ditemukan");
        }

        // Lakukan request ke API untuk mengambil data Live Streaming
        const response = await axios.get(
          `${API_BASE_URL}/api/livestreams/livestreams`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Set live streams dari data yang diterima
        setLiveStreams(response.data);
      } catch (error) {
        // Jika error 404 (data tidak ditemukan), set live streams sebagai array kosong
        if (error.response && error.response.status === 404) {
          setLiveStreams([]);
        } else {
          // Jika ada error lain, simpan pesan error untuk ditampilkan di UI
          setError("Gagal mengambil data Live Streaming . Silakan coba lagi.");
        }
      } finally {
        // Set loading ke false setelah proses selesai
        setLoading(false);
      }
    };

    // Panggil fungsi untuk mengambil data Live Streaming  saat komponen dimuat
    fetchLiveStreams();
  }, []); // Kosongkan dependency array agar hanya dijalankan sekali pada mount komponen

  useEffect(() => {
    // console.log("Updated usedLive:", userPackage?.subscription?.usedLive);
    const fetchUserPackage = async () => {
      try {
        const token = localStorage.getItem("token");

        // Periksa apakah token tersedia
        if (!token) {
          setError("Token autentikasi tidak ditemukan");
          return;
        }

        // Lakukan request ke API untuk mengambil data paket pengguna
        const response = await axios.get(`${API_BASE_URL}/api/packages/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Ambil remainingDays dari respons backend
        const fetchedRemainingDays = response.data.remainingDays;

        // Set remainingDays ke state
        setRemainingDays(fetchedRemainingDays);

        // Menyimpan informasi paket pengguna
        setUserPackage(response.data);
      } catch (error) {
        // console.error("Error fetching user package:", error); // Log error
        // Set pesan error yang relevan untuk ditampilkan di UI
        setError(
          "Gagal mengambil informasi paket pengguna. Silakan coba lagi."
        );
      }
    };

    // Panggil fungsi untuk mengambil informasi paket pengguna saat komponen dimuat
    fetchUserPackage();
  }, []);

  const handleAction = async () => {
    try {
      const token = localStorage.getItem("token");

      // Validasi input awal
      if (!selectedStreamId) {
        throw new Error("ID Live Streaming tidak valid");
      }

      if (!["stop", "restart", "delete"].includes(actionType)) {
        throw new Error("Jenis aksi tidak valid");
      }

      let response;

      // Menghentikan Live Streaming
      if (actionType === "stop") {
        response = await axios.patch(
          `${API_BASE_URL}/api/livestreams/livestreams/stop/${selectedStreamId}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLiveStreams((prevStreams) =>
          prevStreams.map((stream) =>
            stream._id === selectedStreamId
              ? { ...stream, isLive: false }
              : stream
          )
        );

        // Memulai ulang Live Streaming
      } else if (actionType === "restart") {
        response = await axios.patch(
          `${API_BASE_URL}/api/livestreams/livestreams/restart/${selectedStreamId}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLiveStreams((prevStreams) =>
          prevStreams.map((stream) =>
            stream._id === selectedStreamId
              ? { ...stream, isLive: true }
              : stream
          )
        );

        // Menghapus Live Streaming
      } else if (actionType === "delete") {
        response = await axios.delete(
          `${API_BASE_URL}/api/livestreams/livestreams/${selectedStreamId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLiveStreams((prevStreams) =>
          prevStreams.filter((stream) => stream._id !== selectedStreamId)
        );
      }

      // Menampilkan notifikasi sukses kepada pengguna
      setNotification(
        `${
          actionType === "stop"
            ? "Live Streaming berhasil dihentikan!"
            : actionType === "restart"
            ? "Live Streaming berhasil dimulai ulang!"
            : "Live Streaming berhasil dihapus!"
        }`
      );
    } catch (error) {
      // Menangani kesalahan berdasarkan tipe kesalahan
      if (error.response) {
        setError(
          `Gagal ${actionType} Live Streaming: ${
            error.response.data.message || "Terjadi kesalahan pada server."
          }`
        );
      } else if (error.request) {
        setError(
          "Tidak ada respon dari server. Silakan periksa koneksi internet Anda."
        );
      } else {
        setError(`Gagal ${actionType} Live Streaming: ${error.message}`);
      }
    } finally {
      // Reset state setelah aksi selesai
      setOpenDialog(false);
      setSelectedStreamId(null);
      setActionType("");
    }
  };

  const handleOpenUpdateModal = (stream) => {
    setSelectedStream(stream);
    setNewStartTime(
      moment(stream.scheduleStart).tz("Asia/Jakarta").format("YYYY-MM-DDTHH:mm")
    );
    setNewStopTime(
      moment(stream.scheduleStop).tz("Asia/Jakarta").format("YYYY-MM-DDTHH:mm")
    );
    setOpenUpdateDialog(true);
  };

  const handleRenewPackage = async () => {
    try {
      setLoading(true); // Set loading to true while processing

      // Make API call to request renewal
      const response = await axios.post(
        `${API_BASE_URL}/api/renew/renew`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Get token from local storage
          },
        }
      );

      // Show success message from the server response
      setNotification(response.data.message);
    } catch (error) {
      // Handle errors and show error messages
      if (error.response && error.response.data) {
        setNotification(error.response.data.message);
      } else {
        setNotification("An error occurred, please try again later.");
      }
    } finally {
      setLoading(false); // Turn off loading after the process is complete
      setOpenSnackbarPaket(true); // Open the snackbar to show the result
    }
  };

  const handleOpenDialogPaket = () => {
    setOpenDialogPaket(true); // Open the confirmation dialog
  };

  const handleCloseDialogPaket = () => {
    setOpenDialogPaket(false); // Close the confirmation dialog
  };

  const handleConfirmRenew = () => {
    handleRenewPackage(); // Trigger the renewal process
    handleCloseDialogPaket(); // Close the dialog after confirmation
  };

  const handleCloseSnackbarPaket = () => {
    setOpenSnackbarPaket(false); // Close the snackbar
  };

  const handleUpdateSchedule = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!selectedStream) {
        throw new Error("Stream tidak valid");
      }

      // Validasi jadwal mulai dan berhenti
      if (new Date(newStartTime).getTime() < Date.now()) {
        setError("Jadwal mulai tidak boleh di masa lalu.");
        return;
      }

      if (new Date(newStopTime).getTime() <= new Date(newStartTime).getTime()) {
        setError("Jadwal berhenti harus setelah jadwal mulai.");
        return;
      }

      const response = await axios.patch(
        `${API_BASE_URL}/api/livestreams/livestreams/update/${selectedStream._id}`,
        {
          scheduleStart: newStartTime,
          scheduleStop: newStopTime,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Pastikan response.status adalah 200
      if (response.status === 200) {
        // Update live streams
        setLiveStreams((prevStreams) =>
          prevStreams.map((stream) =>
            stream._id === selectedStream._id
              ? {
                  ...stream,
                  scheduleStart: newStartTime,
                  scheduleStop: newStopTime,
                }
              : stream
          )
        );

        setNotification("Jadwal Live Streaming berhasil diperbarui!");
      } else {
        setError("Gagal memperbarui jadwal Live Streaming. Silakan coba lagi.");
      }
    } catch (error) {
      // console.error("Gagal memperbarui jadwal Live Streaming:", error);
      setError("Gagal memperbarui jadwal Live Streaming. Silakan coba lagi.");
    } finally {
      setOpenUpdateDialog(false);
      setSelectedStream(null);
    }
  };

  const handleOpenDialog = (type, streamId) => {
    setActionType(type);
    setSelectedStreamId(streamId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedStreamId(null);
    setActionType("");
  };

  const getAccordionColor = (isLive, scheduleStart) => {
    const now = moment().tz("Asia/Jakarta");
    const start = moment(scheduleStart).tz("Asia/Jakarta");

    if (isLive) {
      return "#d4edda"; // Hijau jika sedang live
    } else if (start.isAfter(now)) {
      return "#fff3cd"; // Kuning untuk status "Menunggu Live"
    } else {
      return "#f8d7da"; // Merah jika tidak live dan tidak terjadwal
    }
  };

  const getStatusLabel = (isLive, scheduleStart) => {
    const now = moment().tz("Asia/Jakarta");
    const start = moment(scheduleStart).tz("Asia/Jakarta");

    if (isLive) {
      return "Live";
    } else if (start.isAfter(now)) {
      return "Menunggu Live";
    } else {
      return "Tidak Live";
    }
  };

  return (
    <Box p={3}>
      <div>
        <Typography variant="body1" gutterBottom>
          Welcome: {userPackage?.username}
        </Typography>

        {userPackage?.package && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>DATA MEMBER</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Paket</Typography>
                  <Typography>{userPackage?.package?.name}</Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography>Maksimal Live</Typography>
                  <Typography>{userPackage?.package?.maxStreams}</Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography>Penyimpanan</Typography>
                  <Typography>
                    {userPackage?.package?.storage / (1024 * 1024)} MB
                  </Typography>{" "}
                  {/* Convert this too */}
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography>Harga</Typography>
                  <Typography>{userPackage?.package?.price} IDR</Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography>Penyimpanan Terpakai</Typography>
                  <Typography>{userPackage.usedStorage} MB</Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography>Sisa Penyimpanan</Typography>
                  <Typography>{userPackage?.remainingStorage} MB</Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography>Masa Berlaku Paket</Typography>
                  <Typography>
                    {userPackage.remainingDays === 0
                      ? "Paket sudah kadaluarsa"
                      : `${userPackage.remainingDays} hari tersisa`}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography>
                    Jumlah Live: {userPackage.usedLive ?? 0}
                  </Typography>
                </Box>

                <Grid2 item xs={2}>
                  {showRenewButton && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleOpenDialogPaket}
                      disabled={loading}
                      fullWidth
                      size={isMobile ? "small" : "medium"}
                    >
                      {loading ? "Memproses..." : "Perpanjangan Paket"}
                    </Button>
                  )}
                  {message && <p>{message}</p>}
                </Grid2>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </div>

       {loading && <Typography>Memuat...</Typography>}
      {error && <Typography color="error">{error}</Typography>}
      {!loading && !error && liveStreams.length === 0 && (
        <Typography>Tidak ada Live Streaming aktif</Typography>
      )}
      <Box sx={{ p: 2 }}>
        <Grid2 container spacing={2}>
          {liveStreams.map((stream, index) => (
            <Grid2 item xs={12} md={6} key={index}>
              <Paper
                elevation={3}
                sx={{
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: 6,
                  },
                }}
              >
                <Accordion
                  sx={{
                    backgroundColor: getAccordionColor(
                      stream.isLive,
                      stream.scheduleStart
                    ),
                    "&:before": {
                      display: "none",
                    },
                    borderRadius: 1,
                    "& .MuiAccordionSummary-root": {
                      borderRadius: "4px 4px 0 0",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "medium" }}
                    >
                      {stream.title} (
                      {getStatusLabel(stream.isLive, stream.scheduleStart)})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" sx={{ width: 120 }}>
                          Judul Video
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          :
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2, flex: 1 }}>
                          {stream.videoTitle}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" sx={{ width: 120 }}>
                          Kunci
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          :
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2, flex: 1 }}>
                          {stream.streamKey}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" sx={{ width: 120 }}>
                          Status
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          :
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2, flex: 1 }}>
                          {getStatusLabel(stream.isLive, stream.scheduleStart)}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" sx={{ width: 120 }}>
                          Mulai
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          :
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2, flex: 1 }}>
                          {moment(stream.scheduleStart)
                            .tz("Asia/Jakarta")
                            .locale("id")
                            .format("dddd, DD MMM YYYY HH:mm:ss")}
                        </Typography>
                      </Box>

                      {stream.scheduleStop && (
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2" sx={{ width: 120 }}>
                            Stop
                          </Typography>
                          <Typography variant="body2" sx={{ ml: 2 }}>
                            :
                          </Typography>
                          <Typography variant="body2" sx={{ ml: 2, flex: 1 }}>
                            {moment(stream.scheduleStop)
                              .tz("Asia/Jakarta")
                              .locale("id")
                              .format("dddd, DD MMM YYYY HH:mm:ss")}
                          </Typography>
                        </Box>
                      )}

                      <Box sx={{ mt: 2 }}>
                        <Grid2 container spacing={1}>
                          <Grid2 item xs={6} sm={3}>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() =>
                                handleOpenDialog("stop", stream._id)
                              }
                              disabled={!stream.isLive}
                              fullWidth
                              size={isMobile ? "small" : "medium"}
                            >
                              Hentikan
                            </Button>
                          </Grid2>
                          <Grid2 item xs={6} sm={3}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                handleOpenDialog("restart", stream._id)
                              }
                              disabled={stream.isLive}
                              fullWidth
                              size={isMobile ? "small" : "medium"}
                            >
                              Mulai
                            </Button>
                          </Grid2>
                          <Grid2 item xs={6} sm={3}>
                            <Button
                              variant="outlined"
                              color="warning"
                              onClick={() =>
                                handleOpenDialog("delete", stream._id)
                              }
                              fullWidth
                              size={isMobile ? "small" : "medium"}
                            >
                              Hapus
                            </Button>
                          </Grid2>
                          <Grid2 item xs={6} sm={3}>
                            <Button
                              variant="outlined"
                              color="info"
                              onClick={() => handleOpenUpdateModal(stream)}
                              fullWidth
                              size={isMobile ? "small" : "medium"}
                            >
                              Perbarui
                            </Button>
                          </Grid2>
                        </Grid2>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid2>
          ))}
        </Grid2>
      </Box>

      {/* Dialog for confirmation */}
      <Dialog open={openDialogPaket} onClose={handleOpenDialogPaket}>
        <DialogTitle>Konfirmasi Perpanjangan Paket</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apakah Anda yakin ingin memperpanjang paket?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogPaket} color="secondary">
            No
          </Button>
          <Button onClick={handleConfirmRenew} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {actionType === "stop"
            ? "Hentikan Live Streaming"
            : actionType === "restart"
            ? "Mulai Ulang Live Streaming"
            : "Hapus Live Streaming"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {actionType === "stop"
              ? "Apakah Anda yakin ingin menghentikan Live Streaming ini?"
              : actionType === "restart"
              ? "Apakah Anda yakin ingin memulai ulang Live Streaming ini?"
              : "Apakah Anda yakin ingin menghapus Live Streaming ini?"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Batal
          </Button>
          <Button onClick={handleAction} color="secondary">
            {actionType === "stop"
              ? "Hentikan"
              : actionType === "restart"
              ? "Mulai Ulang"
              : "Hapus"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdateDialog}
        onClose={() => setOpenUpdateDialog(false)}
      >
        <DialogTitle>Perbarui Jadwal Live Streaming</DialogTitle>
        <DialogContent>
          <Typography>Jadwal Mulai:</Typography>
          <TextField
            type="datetime-local"
            value={newStartTime}
            onChange={(e) => setNewStartTime(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Typography>Jadwal Berhenti:</Typography>
          <TextField
            type="datetime-local"
            value={newStopTime}
            onChange={(e) => setNewStopTime(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpdateDialog(false)} color="primary">
            Batal
          </Button>
          <Button onClick={handleUpdateSchedule} color="primary">
            Perbarui
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbarPaket}
        autoHideDuration={6000}
        onClose={handleCloseSnackbarPaket}
        message={notification}
      />

      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={() => setNotification("")}
        message={notification}
      />
    </Box>
  );
};

export default HomeScreen;
